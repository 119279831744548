import React, { useEffect, useState } from "react";
import axiosInstance from "./axios";
import EmptyPageText from "./components/EmptyPageText/EmptyPageText";
import Spinner from "./components/Spinner/Spinner";

const App: React.FC = () => {
  const [configuring, setConfiguring] = useState(true);
  const [emptyPageText, setEmptyPageText] = useState("404");
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    if (!configuring) {
      return;
    }

    if (window.location.pathname.startsWith("/_e/")) {
      axiosInstance
        .post("findEStop", {
          url: window.location.href
        })
        .then((res) => {
          const redirectUri = res.data as string;
          setRedirect(redirectUri);
        })
        .finally(() => setConfiguring(false));
      return;
    }

    if (window.location.pathname.startsWith("/survey/")) {
      const [id, code] = window.location.pathname
        .replace("/survey/", "")
        .split("/");
      setRedirect(
        process.env.REACT_APP_FORMS_BASE_URL + `/survey?id=${id}&code=${code}`
      );
      setConfiguring(false);
      return;
    }
    if (window.location.pathname.startsWith("/stop/")) {
      const stopId = window.location.pathname.replace("/stop/", "");
      axiosInstance
        .get("findStop/" + stopId)
        .then((res) => res.data)
        .then((enterprise) =>
          setRedirect(
            process.env.REACT_APP_FORMS_BASE_URL +
            "/stop?enterprise=" +
            enterprise
          )
        )
        .finally(() => setConfiguring(false));
      return;
    }
    const uri = window.location.pathname.substring(1);
    if (!uri) {
      setConfiguring(false);
      return;
    }

    axiosInstance
      .post("findUrl/" + uri)
      .then((res) => res.data)
      .then(async (data) => {
        if (!data) {
          return;
        }
        if (data.isMenuPlan) {
          setEmptyPageText(data.pdfDownloadedText || "Die PDF-Datei wurde heruntergeladen!");
        }

        setRedirect(formatRedirect(data.redirect));
      })
      .finally(() => setConfiguring(false));
  }, [configuring]);

  const formatRedirect = (redirectLink: string): string => {
    const customDomain = process.env.REACT_APP_MENU_CUSTOM_DOMAIN || '';
    const projectBucket = process.env.REACT_APP_BUCKET_DOMAIN || '';
    if (redirectLink && projectBucket && customDomain) {
      return redirectLink.replace(
        projectBucket,
        customDomain
      );
    }
    return redirectLink;
  }

  if (configuring) {
    return <Spinner />;
  }

  if (redirect) {
    window.location.href = redirect;
    return emptyPageText === "404" ? (
      null
    ) : (
      <EmptyPageText text={emptyPageText} />
    );
  }

  return <EmptyPageText text={emptyPageText} />;
};

export default App;
