import React from "react";
import css from "./EmptyPageText.module.scss";

const EmptyPageText: React.FC<{ text?: string; withGif?: boolean }> = ({
  text,
}) => {
  return (
    <div className={css.main}>
      <div className={css.fof}>
        <h1>{text || "404"}</h1>
      </div>
    </div>
  );
};

export default EmptyPageText;
