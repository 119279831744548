import React from "react";
import css from "./Spinner.module.scss";

const Spinner: React.FC = () => {
  return (
    <div className={css.spinner}>
      <div className={css.bounce1} />
      <div className={css.bounce2} />
      <div />
    </div>
  );
};

export default Spinner;
